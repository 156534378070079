import Axios, { AxiosRequestConfig } from "axios";
import bounce from "./bounce";

const axiosClient = Axios.create({
    baseUrl: process.env.REACT_APP_API_BASE as string,
    timeout: 120000,
} as AxiosRequestConfig);

axiosClient.interceptors.request.use(
    (config) => {
        config.params = config.params || {};

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (401 === error.response?.status) {
            bounce(true);
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

export { axiosClient };
