import { showToast } from "../components/Toast";
import { EndpointMapType } from "../types/EndpointMapType";
import bounce from "./bounce";
import endpointMap from "./endpointMap";

type Params = {
    callback: (value?: any) => void;
    calledCallback?: (called: boolean) => void;
    data?: {};
    endpointKey: keyof EndpointMapType;
    errorMessage?: string;
    jwt: string | null;
    params?: {};
    successMessage?: string;
};

const callApi = ({
    callback,
    calledCallback,
    data,
    endpointKey,
    errorMessage,
    jwt,
    params = {},
    successMessage,
}: Params) => {
    const endpointMapElement =
        endpointMap[endpointKey as keyof typeof endpointMap];
    const { endpoint, method, dataKey } = endpointMapElement(params);

    if (!jwt) {
        bounce(true);
    }

    fetch(process.env.REACT_APP_API_BASE + endpoint, {
        method,
        headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
            "x-service-id": "1",
        },
        body: data ? JSON.stringify(data) : undefined,
    })
        .then((response) => {
            if (calledCallback) {
                calledCallback(true);
            }
            if (response.ok) {
                if (successMessage) {
                    showToast("success", successMessage);
                }
                return response.json();
            } else {
                if (response.status === 403) {
                    bounce(true);
                }
            }
        })
        .then((result) => {
            if (callback) {
                if (dataKey) {
                    callback(dataKey === "none" ? result : result[dataKey]);
                } else {
                    callback();
                }
            }
        })
        .catch((error) => {
            if (errorMessage) {
                showToast("error", errorMessage);
            }
            if (error.status === 403) {
                bounce(true);
            }
        });
};

export default callApi;
