import { Link } from "react-router-dom";
import { routes } from "../utils/routes";
import Breadcrumbs from "./Breadcrumbs";

const NotFound = () => (
    <div className="p-25">
        <div className="breadcrumb-container">
            <Breadcrumbs
                breadcrumbs={[
                    { link: routes.getHomepageRoute(), text: "Home" },
                ]}
            />
        </div>
        <div className="article-title">404: Not Found</div>
        <p>
            There is no content here.{" "}
            <Link to={routes.getHomepageRoute()}>Return to the home page.</Link>
        </p>
    </div>
);

export default NotFound;
