const markdownTabParser = (markdown: string) => {
    const parts = markdown.split(/(--- tabs ---|--- end tabs ---)/);

    let inTabs = false;
    const processedParts = parts.map((part) => {
        if (part === "--- tabs ---") {
            part = "";
            inTabs = true;
        } else if (part === "--- end tabs ---") {
            part = "";
            inTabs = false;
        }

        if (inTabs) {
            const titles: string[] = [];
            const blocks: string[] = [];

            const codeBlockLines: string[] = part.split("\n");
            let inCodeBlock = false;
            let hasTitle = false;

            let codeBlock = "";

            codeBlockLines.forEach((codeBlockLine: string) => {
                if (codeBlockLine.match(/```.* .*/)) {
                    inCodeBlock = true;
                }
                if (inCodeBlock) {
                    if (!hasTitle) {
                        const codeBlockHeaderParts = codeBlockLine.split(" ");
                        titles.push(codeBlockHeaderParts[1]);
                        codeBlockLine = codeBlockHeaderParts[0];
                        hasTitle = true;
                    }
                }
                if (codeBlockLine !== "") {
                    codeBlock = codeBlock + "\n" + codeBlockLine;
                }
                if (codeBlockLine === "```") {
                    blocks.push(codeBlock);
                    codeBlock = "";
                    inCodeBlock = false;
                    hasTitle = false;
                }
            });
            return {
                type: "tabbedCodeBlock",
                titles,
                blocks,
            };
        } else {
            return { type: "markdown", value: part };
        }
    });
    return processedParts;
};

export default markdownTabParser;
