import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import rehypeRaw from "rehype-raw";

type Props = {
    markdown: string;
};

const Markdown = ({ markdown }: Props) => (
    <ReactMarkdown
        children={markdown}
        components={{
            code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                    <SyntaxHighlighter
                        children={String(children).replace(/\n$/, "")}
                        style={github}
                        language={match[1]}
                        PreTag="div"
                        customStyle={{ padding: "1em", height: 200 }}
                    />
                ) : (
                    <code className={className} {...props}>
                        {children}
                    </code>
                );
            },
        }}
        rehypePlugins={[rehypeRaw] as any}
    />
);

export default Markdown;
