import { useEffect, useState } from "react";

import Button from "../Button";
import Modal from "./Modal";

import copy from "../../utils/copy";

import { ApiKeyParamsType } from "../../types/ApiKeyParamsType";

import { usePermissions } from "../../utils/usePermissions";
import { useServices } from "../../utils/useServices";
import { useUser } from "../../contexts/UserContext";

import { PermissionType } from "../../types/PermissionType";
import { ServiceType } from "../../types/ServiceType";

let key = "";

type Props = {
    show: boolean;
    closeModal: () => void;
    createKey: (
        apiKey: ApiKeyParamsType,
        setGeneratedKey: (newKey: string | undefined) => void
    ) => void;
};

const CreateExcelPluginKeyModal = ({ show, closeModal, createKey }: Props) => {
    const [_generatedKey, setGeneratedKey] = useState("");
    const [copied, setCopied] = useState(false);

    const user = useUser();

    const { data: permissions } = usePermissions(user);
    const { data: services } = useServices(user);

    useEffect(() => {
        if (services && permissions && key === "" && show) {
            createKey(
                {
                    name: "Excel Plug-In Key",
                    services: services.map((s: ServiceType) => s.id),
                    permissions: permissions.map(
                        (p: PermissionType) => p.permissionId
                    ),
                },
                (newKey: string | undefined) => {
                    key = newKey || "";
                    setGeneratedKey(key);
                }
            );
        }
    }, [services, permissions, show, createKey]);

    const closeCreateKey = () => {
        if (key !== "") {
            copy(key);
        }
        closeModal();
        setGeneratedKey("");
        key = "";
        setCopied(false);
    };

    return (
        <Modal
            show={show}
            close={closeCreateKey}
            label="Create Excel Plug-In Key"
        >
            <div
                style={{
                    marginTop: 25,
                }}
            >
                <label>
                    Please copy and save this key in a secure place. After you
                    close this dialog you cannot view it.
                </label>
                <br />
                <span
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 30,
                        marginTop: 9,
                        fontSize: 14,
                    }}
                    onClick={() => {
                        copy(key);
                        setCopied(true);
                    }}
                >
                    {copied ? <em>copied</em> : "copy"}
                </span>
                <input
                    type="text"
                    name="newKey"
                    disabled={true}
                    className="mt-5"
                    style={{
                        boxSizing: "border-box",

                        paddingLeft: 8,

                        height: 30,
                        width: "100%",

                        background: "#FFFFFF",
                        border: "1px solid #E6E6E6",

                        borderRadius: 3,
                    }}
                    value={key === "" ? "Loading..." : key}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    height: 47,
                }}
            >
                <Button
                    variant="secondary"
                    text="Copy &amp; Close"
                    onClick={closeCreateKey}
                />
            </div>
        </Modal>
    );
};

export default CreateExcelPluginKeyModal;
