import Breadcrumbs from "./Breadcrumbs";
import { useVersion } from "../contexts/VersionContext";
import { routes } from "../utils/routes";
import { useWelcome } from "../utils/useWelcome";
import { Helmet } from "react-helmet";
import FeaturedArticles from "./FeaturedArticles";
import ExampleArticles from "./ExampleArticles";
import UseCaseArticles from "./UseCaseArticles";
import CaseStudyArticles from "./CaseStudyArticles";
import { useFeatureFlag } from "configcat-react";

const Documentation = () => {
    const version = useVersion();
    const { data: welcome } = useWelcome(version.active);
    const { value: showDocumentation, loading: showDocumentationLoading } =
        useFeatureFlag("showDocumentation", false);

    if (!showDocumentationLoading && !showDocumentation) {
        window.location.href = "/";
    }

    return (
        <div className="p-25 documentation-container">
            <Helmet>
                <title>
                    Documentation: Version {version.active} - Solovis Portfolio
                    API Developer Portal
                </title>
            </Helmet>
            <div className="breadcrumb-container">
                <Breadcrumbs
                    breadcrumbs={[
                        { link: routes.getHomepageRoute(), text: "Home" },
                        {
                            link: routes.getDocumentationRoute(version.active),
                            text: "Documentation",
                        },
                    ]}
                />
            </div>
            {welcome && (
                <div className="welcome-container">
                    <div>
                        <h1 className="welcome-title">{welcome.title}</h1>
                        <p className="welcome-text">{welcome.text}</p>
                    </div>
                    {welcome.image && (
                        <img
                            alt="Welcome"
                            className="welcome-image"
                            src={`${welcome.image}`}
                        />
                    )}
                </div>
            )}
            <FeaturedArticles />
            <ExampleArticles />
            <UseCaseArticles />
            <CaseStudyArticles />
        </div>
    );
};

export default Documentation;
