import { EndpointMapElementParamType } from "../types/EndpointMapElementParamType";
import {
    EndpointMapElementType,
    EndpointMapType,
} from "../types/EndpointMapType";

const endpointMap: EndpointMapType = {
    createApiKey: ({ userId }: EndpointMapElementParamType) =>
        ({
            endpoint: `/v1/auth/users/${userId}/keys`,
            method: "POST",
            dataKey: "none",
        } as EndpointMapElementType),
    fetchNewKey: ({ apiKeyId, userId }: EndpointMapElementParamType) =>
        ({
            endpoint: `/v1/auth/users/${userId}/keys/${apiKeyId}/regenerate`,
            method: "POST",
            dataKey: "key",
        } as EndpointMapElementType),
    destroyApiKey: ({ apiKeyId, userId }: EndpointMapElementParamType) =>
        ({
            endpoint: `/v1/auth/users/${userId}/keys/${apiKeyId}`,
            method: "DELETE",
        } as EndpointMapElementType),
};

export default endpointMap;
