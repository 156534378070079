import { ApiKeyParamsType } from "../types/ApiKeyParamsType";
import { UserType } from "../types/UserType";
import callApi from "./callApi";

const createApiKey = (
    user: UserType,
    data: {},
    callback: (createdApiKey: ApiKeyParamsType) => void
) =>
    callApi({
        callback,
        data: { properties: data },
        endpointKey: "createApiKey",
        errorMessage: "Error creating new key. Please try again",
        jwt: user.jwt,
        params: { userId: user.userId },
        successMessage: "New key has been created",
    });

export default createApiKey;
