import Button from "../Button";
import ListItem from "../ListItem";
import Modal from "./Modal";

import { ApiKeyType } from "../../types/ApiKeyType";
import { PermissionType } from "../../types/PermissionType";
import { ServiceType } from "../../types/ServiceType";

type Props = {
    apiKey: ApiKeyType | undefined;
    closeModal: () => void;
};

const ViewApiKeyModal = ({ apiKey, closeModal }: Props) => {
    return !!apiKey ? (
        <Modal show={true} close={closeModal} label="Key Details">
            <div style={{ marginTop: 25 }}>
                <label
                    style={{
                        display: "inline-block",
                        width: 150,
                        fontWeight: "bold",
                        verticalAlign: "top",
                    }}
                >
                    Key Name
                </label>
                <span>{apiKey.name}</span>
            </div>
            <ListItem
                label="Environments"
                array={apiKey.services}
                displayFunction={(item: PermissionType | ServiceType) =>
                    `${item.id} - ${item.name}`
                }
            />
            <ListItem
                label="Permissions"
                array={apiKey.permissions}
                displayFunction={(item: PermissionType | ServiceType) =>
                    item.name
                }
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    height: 87,
                }}
            >
                <Button variant="primary" text="Close" onClick={closeModal} />
            </div>
        </Modal>
    ) : (
        <></>
    );
};

export default ViewApiKeyModal;
