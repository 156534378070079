import Popup from "reactjs-popup";
import { useVersion, useUpdateActiveVersion } from "../contexts/VersionContext";
import caretIcon from "../images/caret-icon.png";

const VersionSwitcher = () => {
    const version = useVersion();
    const updateActiveVersion = useUpdateActiveVersion();

    return (
        <>
            {version.all.length > 0 && (
                <div className="dropdown-container">
                    API Version:{" "}
                    <Popup
                        className="version-switcher"
                        trigger={
                            <span className="cursor-pointer version-switcher-selector">
                                <span className="settings-dropdown-text">
                                    {version.active}
                                </span>
                                <img
                                    src={caretIcon}
                                    alt="down caret"
                                    className="settings-dropdown-caret"
                                />
                            </span>
                        }
                        on="click"
                        closeOnDocumentClick
                        closeOnEscape
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        arrow={false}
                        position="bottom right"
                    >
                        {version.all.map((version: string) => (
                            <div
                                className="version-switcher-selection"
                                onClick={() => {
                                    updateActiveVersion(version);
                                }}
                                key={version}
                            >
                                Version {version}
                            </div>
                        ))}
                    </Popup>
                </div>
            )}
        </>
    );
};

export default VersionSwitcher;
