import Modal from "./Modal";
import Button from "../Button";
import trashIcon from "../../images/trash-icon.png";

type Props = {
    show: boolean;
    closeModal: () => void;
};

const ExistingExcelPluginKeyInstructionsModal = ({
    show,
    closeModal,
}: Props) => {
    return (
        <Modal show={show} close={closeModal} label="Excel Plug-In Key Exists">
            <p>
                You already have a key named "Excel Plug-In Key". To regenerate
                it, please do the following:
            </p>
            <ul>
                <li>Close this dialog</li>
                <li>
                    Delete the existing key named "Excel Plug-In Key" using the{" "}
                    <img
                        src={trashIcon}
                        alt="trash icon"
                        style={{
                            verticalAlign: "middle",
                            height: 16,
                            width: 16,
                        }}
                    />{" "}
                    icon
                </li>
                <li>Click the "Create Excel Plug-In Key" button again</li>
            </ul>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    height: 87,
                }}
            >
                <Button variant="secondary" text="Close" onClick={closeModal} />
            </div>
        </Modal>
    );
};

export default ExistingExcelPluginKeyInstructionsModal;
