import React from "react";
import { Link } from "react-router-dom";
import { BreadcrumbType } from "../types/BreadcrumbType";
import breadcrumbSeparator from "../images/breadcrumb-separator.png";

type Props = {
    breadcrumbs: BreadcrumbType[];
};

const Breadcrumbs = ({ breadcrumbs }: Props) => (
    <div>
        {breadcrumbs.map((breadcrumb: BreadcrumbType, index: number) => (
            <React.Fragment key={breadcrumb.link}>
                <Link
                    to={breadcrumb.link}
                    className={`breadcrumb-link${
                        breadcrumbs.length <= index + 1
                            ? " breadcrumb-link-active"
                            : ""
                    }`}
                >
                    {breadcrumb.text}
                </Link>
                {breadcrumbs.length > index + 1 && (
                    <img
                        src={breadcrumbSeparator}
                        className="breadcrumb-separator"
                        alt="breadcrumb separator"
                    />
                )}
            </React.Fragment>
        ))}
    </div>
);

export default Breadcrumbs;
