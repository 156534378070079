import { ArticleType } from "../types/ArticleType";
import { routes } from "../utils/routes";
import SidebarLink from "./SidebarLink";

type Props = {
    featuredArticles: ArticleType[];
    version: string;
};

const ArticleNav = ({ featuredArticles, version }: Props) => (
    <>
        <div className="mt-5">
            {featuredArticles.map((article: ArticleType) => (
                <SidebarLink
                    key={article.slug}
                    to={routes.getArticleRoute(version, article.slug)}
                    text={article.title}
                />
            ))}
        </div>
        <SidebarLink
            to={routes.getArticlesRoute(version, "use-case")}
            text="Use Cases"
        />
        <SidebarLink
            to={routes.getArticlesRoute(version, "example")}
            text="Examples"
        />
        <SidebarLink
            to={routes.getArticlesRoute(version, "case-study")}
            text="Case Studies"
        />
    </>
);

export default ArticleNav;
