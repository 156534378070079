import headers from "./headers";
import { useQuery } from "react-query";
import contentfulHeaders from "./contentfulHeaders";
import urls from "./urls";
import { ArticleType } from "../types/ArticleType";
import { axiosClient } from "./axiosClient";

type ArticleReturnType = {
    sys: {
        id: number;
    };
    fields: {
        slug: string;
        title: string;
        shortDescription: string;
        body: string;
        icon: {
            sys: {
                id: string;
            };
        };
        articleType: string;
    };
};

type Asset = {
    fields: {
        file: {
            url: string;
        };
    };
};

const getArticles = async (
    version: string,
    articleType: string,
    onlyFeatured: boolean
) => {
    const { data } = await axiosClient.get(
        onlyFeatured
            ? urls.fetchFeaturedArticles(version, articleType)
            : urls.fetchArticles(version, articleType),
        {
            headers: {
                ...headers,
                ...contentfulHeaders,
            },
        }
    );

    const articles = data.items.map((item: ArticleReturnType) => {
        let icon = null;
        if (item.fields.icon && data?.includes?.Asset?.length > 0) {
            icon = data.includes.Asset.find((asset: Asset) =>
                asset.fields?.file?.url?.includes(item.fields.icon.sys.id)
            );
            if (icon) {
                icon = `https:${icon.fields.file.url}`;
            }
        }

        return {
            article_type: item.fields.articleType,
            body: item.fields.body,
            description: item.fields.shortDescription,
            icon: icon,
            id: item.sys.id,
            slug: item.fields.slug,
            title: item.fields.title,
        } as ArticleType;
    });

    return articles;
};

const useArticles = (
    version: string,
    articleType: string,
    onlyFeatured: boolean = false
) =>
    useQuery(
        ["articles", version, articleType, onlyFeatured],
        () => getArticles(version, articleType, onlyFeatured),
        { enabled: !!version && !!articleType }
    );

export { useArticles };
