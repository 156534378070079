import { MouseEventHandler } from "react";

type Props = {
    icon: string;
    alt: string;
    action: MouseEventHandler<HTMLButtonElement>;
    style?: {};
};

const ActionIcon = ({ icon, alt, action, style = {} }: Props) => (
    <button className="action-icon-button" onClick={action}>
        <img src={icon} alt={alt} className="action-icon" style={style} />
    </button>
);

export default ActionIcon;
