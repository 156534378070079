import { PermissionType } from "../types/PermissionType";
import { ServiceType } from "../types/ServiceType";

type Props = {
    label: string;
    array: PermissionType[] | ServiceType[];
    displayFunction: (item: PermissionType | ServiceType) => string;
};

const ListItem = ({ label, array, displayFunction }: Props) => (
    <div className="mt-15">
        <label className="list-item-label">{label}</label>

        <div className="list-item-block">
            {(array || []).length > 0 ? (
                array.map((item: PermissionType | ServiceType) => (
                    <span key={item.id}>
                        {displayFunction(item)}
                        <br />
                    </span>
                ))
            ) : (
                <>--</>
            )}
        </div>
    </div>
);

export default ListItem;
