import headers from "./headers";
import { useQuery } from "react-query";
import urls from "./urls";
import { axiosClient } from "./axiosClient";
import { UserType } from "../types/UserType";
import apiHeaders from "./apiHeaders";

const getPermissions = async (user: UserType) => {
    let permissions = [];

    if (user.userId) {
        const { data } = await axiosClient.get(
            urls.fetchPermissions(),
            {
                headers: {
                    ...headers,
                    ...apiHeaders,
                    ...{ Authorization: `Bearer ${user.jwt}` },
                },
            }
        );

        permissions = data.permissions;
    }

    return permissions;
};

const usePermissions = (user: UserType) =>
    useQuery(["permissions", user], () => getPermissions(user), {
        enabled: !!user.userId,
    });

export { usePermissions };
