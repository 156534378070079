import { Link, useLocation } from "react-router-dom";

type Props = {
    to: string;
    text: string;
};

const SidebarLink = ({ to, text }: Props) => {
    const pathname = useLocation().pathname;

    return (
        <Link
            to={to}
            className={`article-nav-link${
                pathname === to ? " article-nav-link-active" : ""
            }`}
        >
            {text}
        </Link>
    );
};

export default SidebarLink;
