import { useState } from "react";
import apiReferenceIcon from "../images/laptop-code-solid.svg";
import keyMgmtIcon from "../images/key-solid.svg";
import homeIcon from "../images/house-solid.svg";
import documentationIcon from "../images/book-solid.svg";
import chevronOpen from "../images/chevron-open.svg";
import chevronClosed from "../images/chevron-closed.svg";
import { Link } from "react-router-dom";
import ArticleNav from "./ArticleNav";
import { useVersion } from "../contexts/VersionContext";
import { routes } from "../utils/routes";
import { useArticles } from "../utils/useArticles";
import { useFeatureFlag } from "configcat-react";
import useSection from "../utils/useSection";

const Sidebar = () => {
    const version = useVersion();
    const { data: featuredArticles } = useArticles(version.active, "featured");
    const { value: showDocumentation, loading: showDocumentationLoading } =
        useFeatureFlag("showDocumentation", false);
    const [showDocumentationSubmenu, setShowDocumentationSubmenu] =
        useState(false);

    const section = useSection();

    return version ? (
        <div className="sidebar-container">
            <div>
                <Link to={routes.getHomepageRoute()} className="sidebar-link">
                    <img
                        src={homeIcon}
                        alt="Home"
                        className="sidebar-link-icon"
                    />
                    <span className="sidebar-link-text">Home</span>
                </Link>
            </div>
            {!showDocumentationLoading && showDocumentation && (
                <div>
                    <img
                        src={
                            showDocumentationSubmenu
                                ? chevronOpen
                                : chevronClosed
                        }
                        alt={
                            showDocumentationSubmenu
                                ? "Hide Documentation Submenu"
                                : "Show Documentation Submenu"
                        }
                        style={{
                            float: "right",
                            height: 16,
                            width: 17,
                            paddingTop: 16,
                            marginRight: 10,
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            setShowDocumentationSubmenu(
                                !showDocumentationSubmenu
                            )
                        }
                    />
                    <Link
                        to={routes.getDocumentationRoute(version.active)}
                        className="sidebar-link"
                    >
                        <img
                            src={documentationIcon}
                            alt="Documentation"
                            className="sidebar-link-icon"
                        />
                        <span
                            className={`sidebar-link-text${
                                section === "documentation"
                                    ? " sidebar-link-text-active"
                                    : ""
                            }`}
                        >
                            Documentation
                        </span>
                    </Link>
                    {showDocumentationSubmenu && version && (
                        <ArticleNav
                            featuredArticles={featuredArticles || []}
                            version={version.active}
                        />
                    )}
                </div>
            )}
            <div>
                <Link
                    to={routes.getApiReferenceRoute(version.active)}
                    className="sidebar-link"
                >
                    <img
                        src={apiReferenceIcon}
                        alt="API Reference"
                        className="sidebar-link-icon"
                    />
                    <span
                        className={`sidebar-link-text${
                            section === "api-reference"
                                ? " sidebar-link-text-active"
                                : ""
                        }`}
                    >
                        API Reference
                    </span>
                </Link>
            </div>
            <div>
                <Link to={routes.getKeyMgmtRoute()} className="sidebar-link">
                    <img
                        src={keyMgmtIcon}
                        alt="Key Management"
                        className="sidebar-link-icon"
                    />
                    <span
                        className={`sidebar-link-text${
                            section === "key-mgmt"
                                ? " sidebar-link-text-active"
                                : ""
                        }`}
                    >
                        Key Management
                    </span>
                </Link>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Sidebar;
