import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ConfigCatProvider } from "configcat-react";

import ApiReference from "./components/ApiReference";
import Article from "./components/Article";
import Articles from "./components/Articles";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Documentation from "./components/Documentation";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import KeyMgmt from "./components/KeyMgmt";
import Layout from "./components/Layout";
import NotFound from "./components/NotFound";
import { UserProvider } from "./contexts/UserContext";
import { VersionProvider } from "./contexts/VersionContext";
import { links } from "./utils/routes";
import { Toast } from "./components/Toast";
import Footer from "./components/Footer";
import ScrollToTop from "./utils/scrollToTop";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
        },
    },
});

const App = () => (
    <>
        <ConfigCatProvider
            sdkKey={process.env.REACT_APP_CONFIGCAT_SDK_KEY || ""}
        >
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ScrollToTop />
                    <VersionProvider>
                        <UserProvider>
                            <Header />
                            <Routes>
                                <Route
                                    path={links.homepage}
                                    element={<Homepage />}
                                />
                                <Route element={<Layout />}>
                                    <Route
                                        path={links.apiReference}
                                        element={<ApiReference />}
                                    />
                                    <Route
                                        path={links.article}
                                        element={<Article />}
                                    />
                                    <Route
                                        path={links.featuredArticles}
                                        element={
                                            <Articles
                                                slug="featured-articles"
                                                articleType="featured"
                                                title="Featured"
                                            />
                                        }
                                    />
                                    <Route
                                        path={links.useCaseArticles}
                                        element={
                                            <Articles
                                                slug="use-case-articles"
                                                articleType="use case"
                                                title="Use Case"
                                            />
                                        }
                                    />
                                    <Route
                                        path={links.exampleArticles}
                                        element={
                                            <Articles
                                                slug="example-articles"
                                                articleType="example"
                                                title="Example"
                                            />
                                        }
                                    />
                                    <Route
                                        path={links.caseStudyArticles}
                                        element={
                                            <Articles
                                                slug="case-study-articles"
                                                articleType="case study"
                                                title="Case Study"
                                            />
                                        }
                                    />
                                    <Route
                                        path={links.documentation}
                                        element={<Documentation />}
                                    />
                                    <Route element={<ProtectedRoutes />}>
                                        <Route
                                            path={links.keyMgmt}
                                            element={<KeyMgmt />}
                                        />
                                    </Route>
                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            </Routes>
                            <Footer />
                        </UserProvider>
                    </VersionProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </ConfigCatProvider>
        <Toast />
    </>
);

export default App;
