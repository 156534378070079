import logo from "../images/nasdaq-logo.svg";
import caretIcon from "../images/caret-icon.png";
import newWindowIcon from "../images/new-window-icon.png";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import VersionSwitcher from "./VersionSwitcher";

import { useVersion } from "../contexts/VersionContext";
import { routes } from "../utils/routes";
import { useUser } from "../contexts/UserContext";
import bounce from "../utils/bounce";
import { useFeatureFlag } from "configcat-react";
import useSection from "../utils/useSection";

const Header = () => {
    const version = useVersion();
    const user = useUser();
    const section = useSection();
    const { value: showDocumentation, loading: showDocumentationLoading } =
        useFeatureFlag("showDocumentation", false);

    return (
        <header className="header-container">
            <div className="header-container-left">
                <Link to={routes.getHomepageRoute()}>
                    <div className="header-logo-container">
                        <img src={logo} alt="Nasdaq" className="header-logo" />
                    </div>
                    <span className="header-title">Solovis API</span>
                </Link>
                <span className="version-switcher-container">
                    <VersionSwitcher />
                </span>
            </div>
            <div className="header-container-right">
                {!showDocumentationLoading && showDocumentation && (
                    <Link
                        to={routes.getDocumentationRoute(version.active)}
                        className={`header-nav-link ${
                            section === "documentation" &&
                            "header-nav-link-current"
                        }`}
                    >
                        Documentation
                    </Link>
                )}
                <Link
                    to={routes.getApiReferenceRoute(version.active)}
                    className={`header-nav-link ${
                        section === "api-reference" && "header-nav-link-current"
                    }`}
                >
                    API Reference
                </Link>
                <Link
                    to={routes.getKeyMgmtRoute()}
                    className={`header-nav-link ${
                        section === "key-mgmt" && "header-nav-link-current"
                    }`}
                >
                    Key Management
                </Link>
                {user.email ? (
                    <span className="settings-dropdown-container">
                        <Popup
                            trigger={
                                <span className="cursor-pointer header-nav-link">
                                    <span className="settings-dropdown-text">
                                        {user.email}
                                    </span>
                                    <img
                                        src={caretIcon}
                                        alt="down caret"
                                        className="settings-dropdown-caret"
                                    />
                                </span>
                            }
                            on="hover"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            className="settings-dropdown-popup"
                            arrow={false}
                            position="bottom right"
                        >
                            <div
                                onClick={() => bounce(false)}
                                className="cursor-pointer"
                            >
                                Sign Out
                            </div>
                        </Popup>
                    </span>
                ) : (
                    <div
                        className="settings-dropdown-container alternative-sign-in-dropdown-container"
                        style={{ padding: "6px 14px", height: 34 }}
                    >
                        <a
                            className="alternative-sign-in-link"
                            href={process.env.REACT_APP_GATEWAY_URL}
                            style={{
                                paddingRight:
                                    process.env
                                        .REACT_APP_ALTERNATE_GATEWAY_URL && 10,
                                borderRight:
                                    process.env
                                        .REACT_APP_ALTERNATE_GATEWAY_URL &&
                                    "1px solid #68839c",
                            }}
                        >
                            Sign In
                        </a>

                        {process.env.REACT_APP_ALTERNATE_GATEWAY_URL && (
                            <span style={{ display: "inline-block" }}>
                                <Popup
                                    className="alternate-sign-in"
                                    trigger={
                                        <span className="cursor-pointer">
                                            <span className="settings-dropdown-text">
                                                {user.email}
                                            </span>
                                            <img
                                                src={caretIcon}
                                                alt="down caret"
                                                className="settings-dropdown-caret"
                                            />
                                        </span>
                                    }
                                    on="hover"
                                    closeOnDocumentClick
                                    mouseLeaveDelay={300}
                                    mouseEnterDelay={0}
                                    arrow={false}
                                    position="bottom right"
                                >
                                    <a
                                        href={
                                            process.env
                                                .REACT_APP_ALTERNATE_GATEWAY_URL
                                        }
                                        className="alternative-sign-in-link-text"
                                    >
                                        <img
                                            src={newWindowIcon}
                                            alt="alternate login"
                                            className="new-window-icon"
                                        />
                                        Alternate
                                    </a>
                                </Popup>
                            </span>
                        )}
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
