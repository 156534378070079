const pluralArticleTypes: { [key: string]: string } = {
    "Case Study": "Case Studies",
    "case study": "Case Studies",
    Featured: "Features",
    featured: "Features",
};

const pluralizeArticleType = (title: string) =>
    pluralArticleTypes[title] || `${title}s`;

export default pluralizeArticleType;
