import { useState } from "react";
import copy from "../utils/copy";
import Markdown from "./Markdown";
import { showToast } from "../components/Toast";

import copyIcon from "../images/copy.svg";
import copiedIcon from "../images/copied.svg";

type Props = {
    titles: string[];
    blocks: string[];
};

const TabbedCodeBlock = ({ titles, blocks }: Props) => {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [activeCopyIcon, setActiveCopyIcon] = useState(copyIcon);

    const copyCode = () => {
        const lines = blocks[tabIndex].split("\n");
        lines[0] = "";
        lines[1] = "";
        lines[lines.length - 1] = "";
        copy(lines.join("\n").trim());
        showToast("success", "Code snippet copied");
        setActiveCopyIcon(copiedIcon);
    };

    return (
        <div
            style={{
                position: "relative",
                backgroundColor: "#e6e6e6",
                borderTopRightRadius: 3,
                borderTopLeftRadius: 3,
            }}
        >
            {titles.map((title, index) => (
                <span
                    key={title}
                    onClick={() => {
                        if (tabIndex !== index) {
                            setTabIndex(index);
                            setActiveCopyIcon(copyIcon);
                        }
                    }}
                    className={`code-block-tab${
                        tabIndex === index ? " code-block-tab-current" : ""
                    }`}
                >
                    {title}
                </span>
            ))}
            <div className="code-block-copy-icon" onClick={copyCode}>
                <img src={activeCopyIcon} alt="copy" />
            </div>
            {titles.map((title, index) => (
                <span
                    key={title}
                    style={{
                        display: tabIndex === index ? "block" : "none",
                    }}
                >
                    <Markdown markdown={blocks[index]} />
                </span>
            ))}
        </div>
    );
};

export default TabbedCodeBlock;
