import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useVersion } from "../contexts/VersionContext";
import { ArticleType } from "../types/ArticleType";
import { routes } from "../utils/routes";
import { useArticles } from "../utils/useArticles";
import Breadcrumbs from "./Breadcrumbs";
import Loading from "./Loading";
import NotFound from "./NotFound";
import pluralizeArticleType from "../utils/pluralizeArticleType";

type Props = {
    articleType: string;
    slug: string;
    title: string;
};

const Articles = ({ slug, articleType, title }: Props) => {
    const version = useVersion();
    const {
        data: articles,
        error,
        isFetching,
    } = useArticles(version.active, articleType);

    if (isFetching) {
        return <Loading />;
    } else if (error || (!isFetching && !articles)) {
        return <NotFound />;
    }

    return (
        <div className="p-25">
            <Helmet>
                <title>
                    Documentation: Version {version.active} - {title} - Solovis
                    Portfolio API Developer Portal
                </title>
            </Helmet>
            <div className="article-container">
                <Breadcrumbs
                    breadcrumbs={[
                        { link: routes.getHomepageRoute(), text: "Home" },
                        {
                            link: routes.getDocumentationRoute(version.active),
                            text: "Documentation",
                        },
                        {
                            link: routes.getArticlesRoute(
                                version.active,
                                slug.replace("-articles", "")
                            ),
                            text: `${pluralizeArticleType(title)}`,
                        },
                    ]}
                />
            </div>
            <h2 className="article-h2">{pluralizeArticleType(title)}</h2>
            {articles.map(({ id, title, description, slug }: ArticleType) => (
                <dl key={id}>
                    <dt className="article-title">
                        <Link
                            to={routes.getArticleRoute(version.active, slug)}
                            className="articles-link"
                        >
                            {title}
                        </Link>
                    </dt>
                    <dd className="mt-0">{description}</dd>
                </dl>
            ))}
        </div>
    );
};

export default Articles;
