import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VersionType } from "../types/VersionType";
import { useVersions } from "../utils/useVersions";

type VersionProviderProps = {
    children: React.ReactNode;
};

const nullVersion: VersionType = {
    latest: "",
    active: "",
    all: [],
    fetched: false,
};

const VersionProvider = ({ children }: VersionProviderProps) => {
    const navigate = useNavigate();

    // Not using useParams because this isn't within a Route
    const versionFromParams = window.location.pathname.split("/")[2];

    const [version, setVersion] = useState<VersionType>(nullVersion);
    const { error, data: versions, isFetching } = useVersions();

    if (!isFetching && !error && versions && !version.fetched) {
        const latestVersion = versions?.[0];
        setVersion({
            latest: latestVersion,
            active: versionFromParams || latestVersion,
            all: versions,
            fetched: true,
        });
    }

    const updateActiveVersion = (newActiveVersion: string) => {
        const section = window.location.pathname.split("/")[1];
        const slug = window.location.pathname.split("/")[3];

        setVersion({ ...version, ...{ active: newActiveVersion } });

        if (section) {
            if (slug) {
                navigate(`/${section}/${newActiveVersion}/${slug}`);
            } else {
                navigate(`/${section}/${newActiveVersion}`);
            }
        }
    };

    return (
        <VersionContext.Provider value={version}>
            <UpdateActiveVersionContext.Provider value={updateActiveVersion}>
                {children}
            </UpdateActiveVersionContext.Provider>
        </VersionContext.Provider>
    );
};

const VersionContext = createContext<VersionType>(nullVersion);
const UpdateActiveVersionContext = createContext<(value: string) => void>(
    () => {}
);

const useVersion = () => useContext(VersionContext);
const useUpdateActiveVersion = () => useContext(UpdateActiveVersionContext);

export { VersionProvider, useVersion, useUpdateActiveVersion };
