const bounce = (redirectToGateway: boolean) => {
    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("username");

    window.location.href = redirectToGateway
        ? process.env.REACT_APP_GATEWAY_URL || "/"
        : "/";
};

export default bounce;
