import { Link } from "react-router-dom";
import Button from "./Button";

type Params = {
    image: string;
    imageAlign: string;
    link: string;
    text: string;
    title: string;
};

const HomepageContentSection = ({
    image,
    imageAlign,
    link,
    text,
    title,
}: Params) => (
    <div className="homepage-content-section">
        {imageAlign === "left" && <img src={image} alt={title} />}
        <div className="homepage-content-text">
            <h1>{title}</h1>
            <p>{text}</p>
            <Button
                variant="secondary"
                text="Learn more"
                onClick={() => (window.location.href = link)}
            />
        </div>
        {imageAlign === "right" && <img src={image} alt={title} />}
    </div>
);

export default HomepageContentSection;
