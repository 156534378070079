const pathToRoute = (
    path: string,
    params: { [s: string]: string | number }
) => {
    let route = path;

    Object.keys(params).forEach((param) => {
        route = route.replace(`:${param}`, `${params[param]}`);
    });

    return route;
};

const documentation = `/documentation/:version`;
export const links = {
    apiReference: "/api-reference/:version",
    article: `${documentation}/:slug`,
    articles: `${documentation}/:articleType`,
    documentation,
    homepage: "/",
    keyMgmt: "/key-mgmt",

    caseStudyArticles: `${documentation}/case-study-articles`,
    exampleArticles: `${documentation}/example-articles`,
    featuredArticles: `${documentation}/featured-articles`,
    useCaseArticles: `${documentation}/use-case-articles`,
};
export const routes = {
    getApiReferenceRoute: (version: string) =>
        pathToRoute(links.apiReference, { version }),
    getArticleRoute: (version: string, slug: string) =>
        pathToRoute(links.article, { version, slug }),
    getArticlesRoute: (version: string, articleType: string) =>
        pathToRoute(links.articles, {
            version,
            articleType: `${articleType.replace(" ", "-")}-articles`,
        }),
    getDocumentationRoute: (version: string) =>
        pathToRoute(links.documentation, { version }),
    getHomepageRoute: () => pathToRoute(links.homepage, {}),
    getKeyMgmtRoute: () => pathToRoute(links.keyMgmt, {}),
};
