import { useParams } from "react-router-dom";
import { useVersion } from "../contexts/VersionContext";
import { useArticle } from "../utils/useArticle";

import Breadcrumbs from "./Breadcrumbs";
import TabbedCodeBlock from "./TabbedCodeBlock";

import markdownTabParser from "../utils/markdownTabParser";
import { routes } from "../utils/routes";
import NotFound from "./NotFound";
import Markdown from "./Markdown";
import Loading from "./Loading";
import { Helmet } from "react-helmet";
import pluralizeArticleType from "../utils/pluralizeArticleType";

const Article = () => {
    const { slug } = useParams();
    const version = useVersion();
    const {
        data: article,
        error,
        isFetching,
    } = useArticle(version.active, slug || "");

    if (isFetching) {
        return <Loading />;
    } else if (error || (!isFetching && !article)) {
        return <NotFound />;
    }

    const breadcrumbs = [
        { link: routes.getHomepageRoute(), text: "Home" },
        {
            link: routes.getDocumentationRoute(version.active),
            text: "Documentation",
        },
    ];

    if (article) {
        if (article.article_type) {
            breadcrumbs.push({
                link: routes.getArticlesRoute(
                    version.active,
                    article.article_type
                ),
                text: pluralizeArticleType(`${article.article_type}`),
            });
        }

        breadcrumbs.push({
            link: routes.getArticleRoute(version.active, article.slug),
            text: article.title,
        });
    }

    return (
        <>
            <Helmet>
                <title>
                    Documentation: Version {version.active} - {article.title} -
                    Solovis Portfolio API Developer Portal
                </title>
            </Helmet>
            {article && (
                <div className="p-25 article-page">
                    <div className="breadcrumb-container">
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                    </div>
                    <div style={{ maxWidth: "80%" }}>
                        <div className="article-title">{article.title}</div>
                        <p>{article.description}</p>
                        {article && (
                            <>
                                <hr />
                                {markdownTabParser(article.body).map(
                                    (part, index) => {
                                        if (part.type === "tabbedCodeBlock") {
                                            return (
                                                <TabbedCodeBlock
                                                    key={`articlePart-${index}`}
                                                    titles={part.titles || []}
                                                    blocks={part.blocks || []}
                                                />
                                            );
                                        } else if (part.type === "markdown") {
                                            return (
                                                <span
                                                    key={`articlePart-${index}`}
                                                >
                                                    <Markdown
                                                        markdown={
                                                            part.value || ""
                                                        }
                                                    />
                                                </span>
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    }
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Article;
