import { routes } from "../utils/routes";
import Breadcrumbs from "./Breadcrumbs";

const Loading = () => (
    <div className="p-25">
        <div className="breadcrumb-container">
            <Breadcrumbs
                breadcrumbs={[
                    { link: routes.getHomepageRoute(), text: "Home" },
                ]}
            />
        </div>
        <div className="article-title">Loading...</div>
    </div>
);

export default Loading;
