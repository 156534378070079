import { createContext, useContext, useState } from "react";
import { UserType } from "../types/UserType";

import queryString from "query-string";
import parseJWT from "../utils/parseJWT";
import bounce from "../utils/bounce";

type UserProviderProps = {
    children: React.ReactNode;
};

const nullUser: UserType = {
    jwt: null,
    userId: null,
    email: null,
};

const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState<UserType>(nullUser);

    const values = queryString.parse(window.location.search);

    const signOut = (redirectToGateway: boolean) => {
        setUser(nullUser);
        bounce(redirectToGateway);
    };

    let jwt: string | null = null;
    let userId: string | null = null;
    let email: string | null = null;

    if (!user.userId) {
        if (values.jwt) {
            jwt = values.jwt as string;

            const parsedJWT = parseJWT(jwt);

            userId = parsedJWT.userId as string;
            email = parsedJWT.email as string;

            sessionStorage.setItem("jwt", jwt);
            sessionStorage.setItem("userId", userId);
            sessionStorage.setItem("email", email);
        }

        if (!jwt) {
            jwt = sessionStorage.getItem("jwt");
            userId = sessionStorage.getItem("userId");
            email = sessionStorage.getItem("email");
        }

        if (jwt && userId && email) {
            setUser({ jwt, userId, email });
        }
    }

    return (
        <UserContext.Provider value={user}>
            <SignOutContext.Provider value={signOut}>
                {children}
            </SignOutContext.Provider>
        </UserContext.Provider>
    );
};

const UserContext = createContext<UserType>(nullUser);
const SignOutContext = createContext<(redirectToGateway: boolean) => void>(
    (_redirectToGateway: boolean) => {}
);

const useUser = () => useContext(UserContext);
const useSignOutUser = () => useContext(SignOutContext);

export { UserProvider, useUser, useSignOutUser };
