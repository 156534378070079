import { Helmet } from "react-helmet";
import { useVersion } from "../contexts/VersionContext";
import Loading from "./Loading";
import Breadcrumbs from "./Breadcrumbs";
import { routes } from "../utils/routes";

const ApiReference = () => {
    const version = useVersion();

    if (!version || !version.active) {
        return <Loading />;
    }

    const adjustSidebarMenu = () => {
        const sidebarMenu = (
            document.getElementById("api-reference-frame") as HTMLIFrameElement
        )?.contentWindow?.document.querySelector(
            ".sidebar-menu"
        ) as HTMLElement;
        sidebarMenu.style.marginTop = "60px";

        const sidebarToggle = (
            document.getElementById("api-reference-frame") as HTMLIFrameElement
        )?.contentWindow?.document.querySelector(
            ".sidebar-toggle"
        ) as HTMLElement;
        sidebarToggle.style.top = "20px";
        sidebarToggle.style.left = "calc(1vw + 100% - 180px)";
        sidebarToggle.style.width = "154px";
        sidebarToggle.style.zIndex = "99";

        const sidebar = (
            document.getElementById("api-reference-frame") as HTMLIFrameElement
        )?.contentWindow?.document.querySelector("#sidebar") as HTMLElement;
        sidebar.style.top = "-40px";
    };

    const bodyCss = `
        body {
            overflow: hidden;
        }
    `;

    return (
        <>
            <style>{bodyCss}</style>
            <Helmet>
                <title>
                    API Reference: Version {version.active} - Solovis Portfolio
                    API Developer Portal
                </title>
            </Helmet>
            <div className="breadcrumb-container api-reference-breadcrumb-container">
                <Breadcrumbs
                    breadcrumbs={[
                        { link: routes.getHomepageRoute(), text: "Home" },
                        {
                            link: routes.getApiReferenceRoute(version.active),
                            text: "API Reference",
                        },
                    ]}
                />
            </div>
            <iframe
                id="api-reference-frame"
                referrerPolicy="no-referrer"
                src={`/default/documentation/portfolio_analytics_${version.active}`}
                className="api-reference-container"
                onLoad={adjustSidebarMenu}
                title="API Reference"
            />
        </>
    );
};

export default ApiReference;
