import { Link } from "react-router-dom";
import { ArticleType } from "../types/ArticleType";
import { routes } from "../utils/routes";
import { useVersion } from "../contexts/VersionContext";
import { useArticles } from "../utils/useArticles";

const UseCaseArticles = () => {
    const version = useVersion();
    const { data: useCaseArticles, isFetching: useCaseIsFetching } =
        useArticles(version.active, "use case");

    return (
        <>
            {useCaseArticles?.length > 0 && (
                <h3 className="documentation-articles-header">Use Cases</h3>
            )}
            {!useCaseIsFetching &&
                useCaseArticles?.map(
                    ({ id, title, description, slug }: ArticleType) => (
                        <div className="flex-space-between" key={id}>
                            <div className="use-case-articles-container">
                                <div
                                    className="documentation-articles-title"
                                    style={{ maxWidth: 500 }}
                                >
                                    {title}
                                </div>
                                <div
                                    className="width-half"
                                    style={{ maxWidth: 1000 }}
                                >
                                    <p className="mt-0">{description}</p>
                                    <Link
                                        to={routes.getArticleRoute(
                                            version.active,
                                            slug
                                        )}
                                        className="documentation-link"
                                    >
                                        <span className="documentation-articles-link">
                                            Learn More
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                )}
        </>
    );
};

export default UseCaseArticles;
