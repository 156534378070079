import headers from "./headers";
import { useQuery } from "react-query";
import contentfulHeaders from "./contentfulHeaders";
import urls from "./urls";
import { axiosClient } from "./axiosClient";

const getArticleBySlug = async (version: string, slug: string) => {
    const { data } = await axiosClient.get(urls.fetchArticle(version, slug), {
        headers: {
            ...headers,
            ...contentfulHeaders,
        },
    });

    const item = data.items[0];
    return (
        item && {
            id: item.sys.id,
            slug: item.fields.slug,
            title: item.fields.title,
            description: item.fields.shortDescription,
            body: item.fields.body,
            icon: null,
            article_type: item.fields.articleType,
        }
    );
};

const useArticle = (version: string, slug: string) =>
    useQuery(
        ["article", version, slug],
        () => getArticleBySlug(version, slug),
        { enabled: !!version && !!slug}
    );

export { useArticle };
