import { ApiKeyType } from "../types/ApiKeyType";
import { UserType } from "../types/UserType";
import callApi from "./callApi";

const fetchNewKey = (
    user: UserType,
    apiKey: ApiKeyType,
    callback: (value: string) => void
) =>
    callApi({
        callback,
        endpointKey: "fetchNewKey",
        jwt: user.jwt,
        params: { apiKeyId: apiKey.id, userId: user.userId },
    });

export default fetchNewKey;
