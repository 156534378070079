import eyeIcon from "../../images/eye-icon.png";
import refreshIcon from "../../images/refresh-icon.png";
import trashIcon from "../../images/trash-icon.png";
import { ApiKeyType } from "../../types/ApiKeyType";

import ActionIcon from "./ActionIcon";

type Props = {
    apiKey: ApiKeyType;
    openViewApiKeyModal: (apiKey: ApiKeyType) => void;
    openRegenerateApiKeyModal: (apiKey: ApiKeyType) => void;
    openDeleteApiKeyModal: (apiKey: ApiKeyType) => void;
};

const TableRow = ({
    apiKey,
    openViewApiKeyModal,
    openRegenerateApiKeyModal,
    openDeleteApiKeyModal,
}: Props) => (
    <tr>
        <td>{apiKey.name}</td>
        <td>{apiKey.updatedAt && new Date(apiKey.updatedAt).toLocaleDateString("en-US")}</td>
        <td align="center">
            <ActionIcon
                icon={eyeIcon}
                alt="View Key"
                action={() => openViewApiKeyModal(apiKey)}
            />
            <ActionIcon
                icon={refreshIcon}
                alt="Regenerate Key"
                action={() => openRegenerateApiKeyModal(apiKey)}
            />
            <ActionIcon
                icon={trashIcon}
                alt="Delete Key"
                action={() => openDeleteApiKeyModal(apiKey)}
            />
        </td>
    </tr>
);

export default TableRow;
