import { ApiKeyType } from "../types/ApiKeyType";
import { UserType } from "../types/UserType";
import callApi from "./callApi";

const destroyApiKey = (
    user: UserType,
    apiKey: ApiKeyType,
    removeApiKey: () => void
) =>
    callApi({
        callback: removeApiKey,
        endpointKey: "destroyApiKey",
        errorMessage: `Error deleting ${apiKey.name}. Please try again`,
        jwt: user.jwt,
        params: { apiKeyId: apiKey.id, userId: user.userId },
        successMessage: `${apiKey.name} has been deleted`,
    });

export default destroyApiKey;
