import headers from "./headers";
import { useQuery } from "react-query";
import urls from "./urls";
import { axiosClient } from "./axiosClient";
import { UserType } from "../types/UserType";
import apiHeaders from "./apiHeaders";

const getServices = async (user: UserType) => {
    let services = [];

    if (user.userId) {
        const { data } = await axiosClient.get(
            urls.fetchServices(user.userId),
            {
                headers: {
                    ...headers,
                    ...apiHeaders,
                    ...{ Authorization: `Bearer ${user.jwt}` },
                },
            }
        );

        services = data.services;
    }

    return services;
};

const useServices = (user: UserType) =>
    useQuery(["services", user], () => getServices(user), {
        enabled: !!user.userId,
    });

export { useServices };
