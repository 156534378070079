import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import greenCheck from "../images/green-check.png";
import errorExclamation from "../images/error-exclamation.png";

const Toast = () => <ToastContainer hideProgressBar={true} autoClose={5000} />;

type VariantMap = {
    [key: string]: {
        [key: string]: string;
    };
};

const variantMap: VariantMap = {
    success: {
        icon: greenCheck,
        headerText: "SUCCESS",
    },
    error: {
        icon: errorExclamation,
        headerText: "ERROR",
    },
};

const showToast = (variant: string, text: string) => {
    toast(
        <small>
            <div className={`toast-${variant}-header`}>
                <img
                    src={variantMap[variant].icon}
                    className="toast-icon"
                    alt="breadcrumb separator"
                />
                {variantMap[variant].headerText}
            </div>
            <div className={`toast-${variant}-body`}>{text}</div>
        </small>,
        {
            className: `toast-${variant}`,
        }
    );
};
export { showToast, Toast };
