type Props = {
    variant: "primary" | "secondary";
    text: string;
    className?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button = ({
    variant,
    text,
    onClick,
    className = "",
    disabled = false,
}: Props) => (
    <button
        className={`button button-${variant} ${className}`}
        disabled={disabled}
        onClick={onClick}
    >
        {text}
    </button>
);
export default Button;
