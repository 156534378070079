import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";

import Button from "../Button";
import Modal from "./Modal";
import SelectAll from "../SelectAll";

import copy from "../../utils/copy";
import reservedKeyNames from "../../utils/reservedKeyNames";

import { ApiKeyParamsType } from "../../types/ApiKeyParamsType";
import { ApiKeyType } from "../../types/ApiKeyType";
import { PermissionType } from "../../types/PermissionType";
import { ServiceType } from "../../types/ServiceType";
import { useUser } from "../../contexts/UserContext";
import { usePermissions } from "../../utils/usePermissions";
import { useServices } from "../../utils/useServices";
import RequiredAsterisk from "../RequiredAsterisk";

let key = "";

type Props = {
    show: boolean;
    closeModal: () => void;
    save: (
        apiKey: ApiKeyParamsType,
        setGeneratedKey: (newKey: string | undefined) => void
    ) => void;
    apiKeys: ApiKeyType[];
};

const CreateApiKeyModal = ({ show, closeModal, save, apiKeys = [] }: Props) => {
    const [selectedServices, setSelectedServices] = useState<ServiceType[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<
        PermissionType[]
    >([]);

    const [newKeyName, setNewKeyName] = useState("");
    const [_generatedKey, setGeneratedKey] = useState("");
    const [copied, setCopied] = useState(false);

    const user = useUser();

    useEffect(() => {
        setSelectedServices([]);
        setSelectedPermissions([]);
        setNewKeyName("");
    }, []);

    const { data: permissions } = usePermissions(user);
    const { data: services } = useServices(user);

    const generateKey = () => {
        save(
            {
                name: newKeyName,
                services: selectedServices.map((s) => s.id),
                permissions: selectedPermissions.map((p) => p.permissionId),
            },
            (newKey: string | undefined) => {
                key = newKey || "";
                setGeneratedKey(key);
            }
        );
    };

    const closeCreateKey = () => {
        if (key !== "") {
            copy(key);
        }
        setNewKeyName("");
        closeModal();
        setGeneratedKey("");
        setSelectedServices([]);
        setSelectedPermissions([]);
        setCopied(false);
        key = "";
    };

    const isNameAvailable =
        !apiKeys.some((apiKey) => apiKey.name === newKeyName) &&
        !reservedKeyNames.includes(newKeyName);

    const canGenerate =
        selectedServices.length > 0 &&
        selectedPermissions.length > 0 &&
        newKeyName.length > 0 &&
        isNameAvailable;

    return (
        <Modal show={show} close={closeCreateKey} label="Create Key">
            <div style={{ marginTop: 25 }}>
                <label><RequiredAsterisk /> Key Name</label>
                <br />
                <input
                    className={`modal-input${
                        !isNameAvailable ? " modal-input-error" : ""
                    }`}
                    name="keyName"
                    onChange={(e) => setNewKeyName(e.target.value)}
                    placeholder="Enter name"
                    type="text"
                    value={newKeyName}
                />
                {!isNameAvailable && (
                    <p className="form-error-message mt-0">
                        Please enter a different name
                    </p>
                )}
            </div>
            <div style={{ marginTop: 15 }}>
                <SelectAll
                    type="service"
                    selected={selectedServices}
                    all={services}
                    setServiceSelected={(value) => setSelectedServices(value)}
                    className="select-all-link"
                />
                <label><RequiredAsterisk /> Environments</label>
                <br />
                <Multiselect
                    displayValue="name"
                    onRemove={setSelectedServices}
                    onSelect={setSelectedServices}
                    options={services}
                    selectedValues={selectedServices}
                    showCheckbox={true}
                />
            </div>
            <div style={{ marginTop: 15 }}>
                <SelectAll
                    type="permission"
                    selected={selectedPermissions}
                    all={permissions}
                    setPermissionSelected={(value) =>
                        setSelectedPermissions(value)
                    }
                    className="select-all-link"
                />
                <label><RequiredAsterisk /> Permissions</label>
                <br />
                <Multiselect
                    displayValue="name"
                    onRemove={setSelectedPermissions}
                    onSelect={setSelectedPermissions}
                    options={permissions}
                    selectedValues={selectedPermissions}
                    showCheckbox={true}
                />
            </div>
            <div
                style={{
                    display: key ? "block" : "none",
                    marginTop: 25,
                }}
            >
                <label>
                    Please copy and save this key in a secure place. After you
                    close this dialog you cannot view it.
                </label>
                <br />
                <span
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 30,
                        marginTop: 9,
                        fontSize: 14,
                    }}
                    onClick={() => {
                        copy(key);
                        setCopied(true);
                    }}
                >
                    {copied ? <em>copied</em> : "copy"}
                </span>
                <input
                    type="text"
                    name="newKey"
                    disabled={true}
                    className="mt-5"
                    style={{
                        boxSizing: "border-box",

                        paddingLeft: 8,

                        height: 30,
                        width: "100%",

                        background: "#FFFFFF",
                        border: "1px solid #E6E6E6",

                        borderRadius: 3,
                    }}
                    value={key}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    height: 47,
                }}
            >
                {key !== "" ? (
                    <Button
                        variant="secondary"
                        text="Copy &amp; Close"
                        onClick={closeCreateKey}
                    />
                ) : (
                    <>
                        <Button
                            variant="secondary"
                            text="Close"
                            onClick={closeCreateKey}
                        />
                        <Button
                            variant="primary"
                            text="Generate Key"
                            onClick={generateKey}
                            disabled={!canGenerate}
                        />
                    </>
                )}
            </div>
        </Modal>
    );
};

export default CreateApiKeyModal;
