import { Link } from "react-router-dom";
import { ArticleType } from "../types/ArticleType";
import { routes } from "../utils/routes";
import { useVersion } from "../contexts/VersionContext";
import { useArticles } from "../utils/useArticles";

const FeaturedArticles = () => {
    const version = useVersion();
    const { data: featuredArticles, isFetching: featuredIsFetching } =
        useArticles(version.active, "featured");

    return (
        <div className="flex-space-between">
            {!featuredIsFetching &&
                featuredArticles?.map(
                    ({ id, title, description, icon, slug }: ArticleType) => (
                        <div className="featured-articles-container" key={id}>
                            <div className="documentation-section-container">
                                {icon && (
                                    <img
                                        alt="Article Icon"
                                        src={icon}
                                        className="featured-articles-icon"
                                    />
                                )}{" "}
                                {title}
                            </div>
                            <p>{description}</p>
                            <Link
                                to={routes.getArticleRoute(
                                    version.active,
                                    slug
                                )}
                                className="documentation-link"
                            >
                                <span className="documentation-more-link">
                                    More →
                                </span>
                            </Link>
                        </div>
                    )
                )}
        </div>
    );
};

export default FeaturedArticles;
