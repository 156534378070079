import headers from "./headers";
import { useQuery } from "react-query";
import contentfulHeaders from "./contentfulHeaders";
import urls from "./urls";
import { axiosClient } from "./axiosClient";

type AssetType = {
    sys: {
        id: number;
    };
};

const getWelcome = async (version: string) => {
    const { data } = await axiosClient.get(urls.fetchWelcome(version), {
        headers: {
            ...headers,
            ...contentfulHeaders,
        },
    });

    const item = data.items[0];
    const asset =
        item?.fields?.image?.sys &&
        data?.includes?.Asset?.find(
            (asset: AssetType) =>
                asset.sys && asset.sys.id === item.fields.image.sys.id
        );
    const imageUrl = asset && `https:${asset.fields.file.url}`;

    return {
        title: item.fields.title,
        text: item.fields.text.content[0].content[0].value,
        image: imageUrl,
    };
};

const useWelcome = (version: string) =>
    useQuery(["welcome", version], () => getWelcome(version), {
        enabled: !!version,
    });

export { useWelcome };
