import { Link } from "react-router-dom";
import { ArticleType } from "../types/ArticleType";
import { routes } from "../utils/routes";
import { useVersion } from "../contexts/VersionContext";
import { useArticles } from "../utils/useArticles";

const ExampleArticles = () => {
    const version = useVersion();
    const { data: exampleArticles, isFetching: exampleIsFetching } =
        useArticles(version.active, "example");

    return (
        <>
            {exampleArticles?.length > 0 && (
                <>
                    <h3 className="documentation-articles-title">Examples</h3>
                    <div className="flex-space-between">
                        {!exampleIsFetching &&
                            exampleArticles?.map(
                                ({ id, title, icon, slug }: ArticleType) => (
                                    <Link
                                        to={routes.getArticleRoute(
                                            version.active,
                                            slug
                                        )}
                                        className="documentation-link example-articles-container"
                                        key={id}
                                    >
                                        {icon && (
                                            <img
                                                alt="Article Icon"
                                                src={icon}
                                                className="example-articles-icon"
                                            />
                                        )}
                                        <div className="documentation-section-container">
                                            {title}
                                        </div>
                                    </Link>
                                )
                            )}
                    </div>
                </>
            )}
        </>
    );
};

export default ExampleArticles;
