import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useSection = () => {
    const [section, setSection] = useState<string | undefined>();

    const pathname = useLocation().pathname;

    useEffect(() => {
        const sectionFromPath = (path: string) => {
            const sections = ["documentation", "api-reference", "key-mgmt"];

            return sections.find((section) => path.includes(section));
        };

        setSection(sectionFromPath(pathname));
    }, [pathname]);

    return section;
};

export default useSection;
