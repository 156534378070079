import { PermissionType } from "../types/PermissionType";
import { ServiceType } from "../types/ServiceType";

type Props = {
    type: "service" | "permission";
    selected: ServiceType[] | PermissionType[];
    all: ServiceType[] | PermissionType[];
    setServiceSelected?: (value: ServiceType[]) => void;
    setPermissionSelected?: (value: PermissionType[]) => void;
    className?: string;
};

const SelectAll = ({
    type,
    selected,
    all,
    setServiceSelected,
    setPermissionSelected,
    className,
}: Props) => {
    return selected.length === all.length ? (
        <span
            className={className}
            onClick={() =>
                type === "service"
                    ? setServiceSelected && setServiceSelected([])
                    : setPermissionSelected && setPermissionSelected([])
            }
        >
            Deselect All
        </span>
    ) : (
        <span
            className={className}
            onClick={() =>
                type === "service"
                    ? setServiceSelected &&
                      setServiceSelected(all as ServiceType[])
                    : setPermissionSelected &&
                      setPermissionSelected(all as PermissionType[])
            }
        >
            Select All
        </span>
    );
};

export default SelectAll;
