const cmsBaseUrl = `${process.env.REACT_APP_DOCUMENTATION_API_BASE}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_PATH}/entries`;
const apiBaseUrl = process.env.REACT_APP_API_BASE;

const moduleId = 2; // hard coding this as we are only using the user key module for now

const cmsUrls = {
    fetchArticle: (version: string, slug: string) =>
        `${cmsBaseUrl}?content_type=article&fields.slug=${slug}&fields.version.sys.contentType.sys.id=version&fields.version.fields.name[all]=${version}`,
    fetchArticles: (version: string, articleType: string) =>
        `${cmsBaseUrl}?content_type=article&fields.articleType=${articleType}&fields.version.sys.contentType.sys.id=version&fields.version.fields.name[all]=${version}`,
    fetchFeaturedArticles: (version: string, articleType: string) =>
        `${cmsBaseUrl}?content_type=article&fields.articleType=${articleType}&fields.version.sys.contentType.sys.id=version&fields.version.fields.name[all]=${version}&fields.featured=true`,
    fetchVersions: () =>
        `${cmsBaseUrl}?content_type=version&order=-fields.name`,
    fetchWelcome: (version: string) =>
        `${cmsBaseUrl}?content_type=welcome&fields.version.sys.contentType.sys.id=version&fields.version.fields.name[all]=${version}`,
};

const apiUrls = {
    fetchApiKeys: (userId: string) =>
        `${apiBaseUrl}/v1/auth/users/${userId}/keys`,
    fetchPermissions: () =>
        `${apiBaseUrl}/v1/auth/modules/${moduleId}/permissions`,
    fetchServices: (userId: string) =>
        `${apiBaseUrl}/v1/auth/services/${userId}`,
};

const urls = { ...cmsUrls, ...apiUrls };

export default urls;
