import { Outlet } from "react-router-dom";
import { useSignOutUser, useUser } from "../contexts/UserContext";

const ProtectedRoutes = () => {
    const user = useUser();
    const signOut = useSignOutUser()

    if (!user.jwt || !user.userId || !user.email) {
        signOut(true);
    }

    return <Outlet />;
};

export default ProtectedRoutes;
