import headers from "./headers";
import { useQuery } from "react-query";
import contentfulHeaders from "./contentfulHeaders";
import urls from "./urls";
import { axiosClient } from "./axiosClient";

type VersionReturnType = {
    fields: {
        name: string;
    };
};

const getVersions = async () => {
    const { data } = await axiosClient.get(urls.fetchVersions(), {
        headers: {
            ...headers,
            ...contentfulHeaders,
        },
    });

    const versions: string[] = data.items.map(
        (item: VersionReturnType) => item.fields.name
    );

    return versions;
};

const useVersions = () => useQuery(["versions"], () => getVersions());

export { useVersions };
