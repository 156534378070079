import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const Layout = () => (
    <main style={{ display: "flex" }}>
        <div className="sidebar-container-container">
            <Sidebar />
        </div>
        <div style={{ flex: "1 0 auto" }}>
            <main className="page"><Outlet /></main>
        </div>
    </main>
);

export default Layout;
