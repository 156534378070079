import interwebsImage from "../images/interwebs.png";
import apiReferenceImage from "../images/api-reference.svg";
import documentationImage from "../images/documentation.svg";
import keyMgmtImage from "../images/key-mgmt.svg";
import "../App.css";
import { useVersion } from "../contexts/VersionContext";
import { routes } from "../utils/routes";
import { Helmet } from "react-helmet";
import { useFeatureFlag } from "configcat-react";
import HomepageContentSection from "./HomepageContentSection";
import Button from "./Button";

const Homepage = () => {
    const version = useVersion();
    const { value: showDocumentation, loading: showDocumentationLoading } =
        useFeatureFlag("showDocumentation", false);

    return (
        <main className="homepage">
            <Helmet>
                <title>Solovis Portfolio API Developer Portal</title>
            </Helmet>
            <div className="homepage-hero">
                <div className="homepage-hero-text">
                    <h1>Solovis API Platform</h1>
                    <p>
                        Solovis provides developers with the tools they need to
                        create bespoke solutions that drive innovation and
                        enhance the performance reporting process.
                    </p>
                    <Button
                        variant="primary"
                        text="Get started"
                        onClick={() =>
                            (window.location.href =
                                !showDocumentationLoading && showDocumentation
                                    ? routes.getDocumentationRoute(
                                          version.active
                                      )
                                    : routes.getApiReferenceRoute(
                                          version.active
                                      ))
                        }
                    />
                </div>
                <img src={interwebsImage} alt="Solovis Documentation" />
            </div>
            <div className="homepage-content">
                {!showDocumentationLoading && showDocumentation && (
                    <HomepageContentSection
                        link={routes.getDocumentationRoute(version.active)}
                        image={documentationImage}
                        imageAlign="left"
                        title="Documentation"
                        text="Get started with our articles on fundamentals
                              of working with the Solovis API or take a deep
                              dive into specific use cases on how to construct
                              integrations with our articles on use cases and
                              client case studies."
                    />
                )}

                <HomepageContentSection
                    link={routes.getApiReferenceRoute(version.active)}
                    image={apiReferenceImage}
                    imageAlign={
                        !showDocumentationLoading && showDocumentation
                            ? "right"
                            : "left"
                    }
                    title="API Reference"
                    text="Explore the wide range of endpoints available for
                          automating tasks and creating custom integrations.
                          Experiment with different calls with the Try It Out
                          feature and when you are satisfied, drop it into
                          your custom code with the code sample feature."
                />

                <HomepageContentSection
                    link={routes.getKeyMgmtRoute()}
                    image={keyMgmtImage}
                    imageAlign={
                        !showDocumentationLoading && showDocumentation
                            ? "left"
                            : "right"
                    }
                    title="Key Management"
                    text="Manage your API Keys with our self-service portal.
                          Create new keys, view keys that have already been
                          created, regenerate keys that have been lost or
                          compromised and delete keys that you no longer need."
                />
            </div>
        </main>
    );
};

export default Homepage;
