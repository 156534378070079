import { useState } from "react";
import { Helmet } from "react-helmet";

import CreateApiKeyModal from "./modals/CreateApiKeyModal";
import CreateExcelPluginKeyModal from "./modals/CreateExcelPluginKeyModal";
import DeleteApiKeyModal from "./modals/DeleteApiKeyModal";
import ExistingExcelPluginKeyInstructionsModal from "./modals/ExistingExcelPluginKeyInstructionsModal";
import RegenerateApiKeyModal from "./modals/RegenerateApiKeyModal";
import ViewApiKeyModal from "./modals/ViewApiKeyModal";

import Button from "./Button";
import Loading from "./Loading";
import TableRow from "./table/TableRow";

import createApiKey from "../utils/createApiKey";
import destroyApiKey from "../utils/destroyApiKey";
import fetchNewKey from "../utils/fetchNewKey";
import reservedKeyNames from "../utils/reservedKeyNames";

import { ApiKeyParamsType } from "../types/ApiKeyParamsType";
import { ApiKeyType } from "../types/ApiKeyType";

import { useApiKeys } from "../utils/useApiKeys";
import { useSignOutUser, useUser } from "../contexts/UserContext";
import Breadcrumbs from "./Breadcrumbs";
import { routes } from "../utils/routes";

const KeyMgmt = () => {
    const [refreshTimestamp, setRefreshTimestamp] = useState<string>(
        new Date().toISOString()
    );

    const [showCreateApiKeyModal, setShowCreateApiKeyModal] = useState(false);
    const [showCreatePlugInKeyModal, setShowCreatePlugInKeyModal] =
        useState(false);
    const [
        showExistingExcelPluginKeyInstructionsModal,
        setShowExistingExcelPluginKeyInstructionsModal,
    ] = useState(false);
    const [viewApiKey, setViewApiKey] = useState<ApiKeyType>();
    const [regenerateApiKey, setRegenerateApiKey] = useState<ApiKeyType>();
    const [deleteApiKey, setDeleteApiKey] = useState<ApiKeyType>();

    const user = useUser();
    const signOut = useSignOutUser();

    const {
        data: apiKeys,
        error,
        isFetching,
    } = useApiKeys(user, refreshTimestamp);

    if (error) {
        signOut(true);
    }

    const addApiKey = (
        apiKey: ApiKeyParamsType,
        setGeneratedKey: (newKey: string | undefined) => void
    ) => {
        createApiKey(user, apiKey, (createdApiKey: ApiKeyParamsType) =>
            setGeneratedKey(createdApiKey.key)
        );
    };

    const removeApiKey = (apiKey: ApiKeyType) => {
        destroyApiKey(user, apiKey, () =>
            setRefreshTimestamp(new Date().toISOString())
        );
    };

    const getNewKey = (
        apiKey: ApiKeyType,
        setGeneratedKey: (apiKey: string | undefined) => void
    ) =>
        fetchNewKey(user, apiKey, (newKey: string) => {
            setGeneratedKey(newKey);
        });

    const handleCloseCreatePlugInKeyModal = () => {
        setRefreshTimestamp(new Date().toISOString());
        setShowCreatePlugInKeyModal(false);
    };
    const handleExistingExcelPluginKeyInstructionsModal = () =>
        setShowExistingExcelPluginKeyInstructionsModal(false);
    const handleCloseCreateApiKeyModal = () => {
        setRefreshTimestamp(new Date().toISOString());
        setShowCreateApiKeyModal(false);
    };
    const handleCloseDeleteApiKeyModal = () => setDeleteApiKey(undefined);
    const handleCloseRegenerateApiKeyModal = () =>
        setRegenerateApiKey(undefined);
    const handleCloseViewApiKeyModal = () => setViewApiKey(undefined);

    const handleOpenDeleteApiKeyModal = (apiKey: ApiKeyType) =>
        setDeleteApiKey(apiKey);
    const handleOpenRegenerateApiKeyModal = (apiKey: ApiKeyType) =>
        setRegenerateApiKey(apiKey);
    const handleOpenViewApiKeyModal = (apiKey: ApiKeyType) =>
        setViewApiKey(apiKey);

    const handleShowCreatePlugInKeyModal = () => {
        apiKeys.some((key: ApiKeyType) => reservedKeyNames.includes(key.name))
            ? setShowExistingExcelPluginKeyInstructionsModal(true)
            : setShowCreatePlugInKeyModal(true);
    };

    return !isFetching ? (
        <div className="m-25">
            <Helmet>
                <title>
                    Key Management -Solovis Portfolio API Developer Portal
                </title>
            </Helmet>
            <div className="breadcrumb-container">
                <Breadcrumbs
                    breadcrumbs={[
                        { link: routes.getHomepageRoute(), text: "Home" },
                        {
                            link: routes.getKeyMgmtRoute(),
                            text: "Key Management",
                        },
                    ]}
                />
            </div>
            <header>
                <Button
                    variant="primary"
                    text="Create API Key"
                    onClick={() => setShowCreateApiKeyModal(true)}
                    className="float-right"
                />
                <Button
                    variant="secondary"
                    text="Create Plug-in Key"
                    onClick={() => handleShowCreatePlugInKeyModal()}
                    className="float-right"
                />
                <span style={{ fontFamily: "Helvetica", fontWeight: 0 }}>
                    Provisioned API Keys
                </span>
            </header>
            <br style={{ clear: "both" }} />
            {apiKeys && apiKeys.length > 0 ? (
                <table className="key-mgmt-table">
                    <thead>
                        <tr>
                            <th align="left">Name</th>
                            <th align="left">Created</th>
                            <th align="center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiKeys.map((apiKey: ApiKeyType) => (
                            <TableRow
                                key={apiKey.id}
                                apiKey={apiKey}
                                openViewApiKeyModal={handleOpenViewApiKeyModal}
                                openRegenerateApiKeyModal={
                                    handleOpenRegenerateApiKeyModal
                                }
                                openDeleteApiKeyModal={
                                    handleOpenDeleteApiKeyModal
                                }
                            />
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>
                    You don't have any keys yet.{" "}
                    <span
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => setShowCreateApiKeyModal(true)}
                    >
                        Create one now.
                    </span>
                </p>
            )}

            <CreateExcelPluginKeyModal
                show={showCreatePlugInKeyModal}
                closeModal={handleCloseCreatePlugInKeyModal}
                createKey={addApiKey}
            />
            <ExistingExcelPluginKeyInstructionsModal
                show={showExistingExcelPluginKeyInstructionsModal}
                closeModal={handleExistingExcelPluginKeyInstructionsModal}
            />
            <CreateApiKeyModal
                show={showCreateApiKeyModal}
                closeModal={handleCloseCreateApiKeyModal}
                save={addApiKey}
                apiKeys={apiKeys}
            />
            <ViewApiKeyModal
                apiKey={viewApiKey}
                closeModal={handleCloseViewApiKeyModal}
            />
            <RegenerateApiKeyModal
                apiKey={regenerateApiKey}
                closeModal={handleCloseRegenerateApiKeyModal}
                getNewKey={getNewKey}
            />
            <DeleteApiKeyModal
                apiKey={deleteApiKey}
                closeModal={handleCloseDeleteApiKeyModal}
                save={removeApiKey}
            />
        </div>
    ) : (
        <Loading />
    );
};

export default KeyMgmt;
