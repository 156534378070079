import { ApiKeyType } from "../../types/ApiKeyType";

import Button from "../Button";
import Modal from "./Modal";

type Props = {
    apiKey: ApiKeyType | undefined;
    closeModal: () => void;
    save: (apiKey: ApiKeyType) => void;
};

const DeleteApiKeyModal = ({ apiKey, closeModal, save }: Props) => {
    const deleteKey = () => {
        if (apiKey) {
            save(apiKey);
        }
        closeModal();
    };

    return !!apiKey ? (
        <Modal show={true} close={closeModal} label="Delete Key">
            <div style={{ marginTop: 25 ? "block" : "none" }}>
                <p>
                    Are you sure you want to delete this key? This cannot be
                    undone.
                </p>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    height: 47,
                }}
            >
                <Button variant="secondary" text="Close" onClick={closeModal} />
                <Button
                    variant="primary"
                    text="Delete Key"
                    onClick={deleteKey}
                />
            </div>
        </Modal>
    ) : (
        <></>
    );
};

export default DeleteApiKeyModal;
