import headers from "./headers";
import { useQuery } from "react-query";
import urls from "./urls";
import { axiosClient } from "./axiosClient";
import { UserType } from "../types/UserType";
import apiHeaders from "./apiHeaders";
import { ApiKeyReturnType } from "../types/ApiKeyReturnType";

const getApiKeys = async (user: UserType) => {
    let apiKeys = [];

    if (user.userId) {
        const { data } = await axiosClient.get(urls.fetchApiKeys(user.userId), {
            headers: {
                ...headers,
                ...apiHeaders,
                ...{ Authorization: `Bearer ${user.jwt}` },
            },
        });

        apiKeys = data.userKeys.sort(
            (a: ApiKeyReturnType, b: ApiKeyReturnType) =>
                a.name > b.name ? 1 : -1
        );
    }

    return apiKeys;
};

const useApiKeys = (user: UserType, refreshTimestamp: string) =>
    useQuery(["apiKeys", user, refreshTimestamp], () => getApiKeys(user), {
        enabled: !!user.userId,
    });

export { useApiKeys };
