import { ReactElement } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("body");

type Props = {
    show: boolean;
    close: () => void;
    label: string;
    children: ReactElement | ReactElement[];
};

function Modal({ show, close, label, children }: Props) {
    return (
        <ReactModal
            appElement={document.getElementById("body") as HTMLElement}
            style={{
                content: {
                    top: 80,
                    bottom: "auto",
                    width: 443,
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    overflow: "visible",
                },
            }}
            isOpen={show}
            contentLabel={label}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontFamily: "Helvetica" }}>{label}</div>
                <div
                    className="modal-close-x"
                    onClick={close}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            close();
                        }
                    }}
                    tabIndex={0}
                >
                    ×
                </div>
            </div>
            {children}
        </ReactModal>
    );
}

export default Modal;
