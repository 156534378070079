import { useState } from "react";

import Button from "../Button";
import Modal from "./Modal";

import copy from "../../utils/copy";

import { ApiKeyType } from "../../types/ApiKeyType";

type Props = {
    apiKey: ApiKeyType | undefined;
    closeModal: () => void;
    getNewKey: (
        apiKey: ApiKeyType,
        setGeneratedKey: (newKey: string | undefined) => void
    ) => void;
};

function RegenerateApiKeyModal({ apiKey, closeModal, getNewKey }: Props) {
    const [generatedKey, setGeneratedKey] = useState("");
    const [copied, setCopied] = useState(false);

    const generateKey = () => {
        if (apiKey) {
            getNewKey(apiKey, (newKey: string | undefined) =>
                setGeneratedKey(newKey || "")
            );
        }
    };

    const closeRegenerateKey = () => {
        if (generatedKey !== "") {
            copy(generatedKey);
        }
        closeModal();
        setGeneratedKey("");
        setCopied(false);
    };

    return !!apiKey ? (
        <Modal show={true} close={closeRegenerateKey} label="Regenerate Key">
            <p style={{ display: generatedKey === "" ? "block" : "none" }}>
                Are you sure you want to regenerate the key? The old one will no
                longer be available.
            </p>
            <div
                style={{
                    marginTop: 25,
                    display: generatedKey === "" ? "none" : "block",
                }}
            >
                <label>
                    Please copy and save this key in a secure place. After you
                    leave this page you cannot view it.
                </label>
                <br />
                <span
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 30,
                        marginTop: 5,
                        fontSize: 14,
                    }}
                    onClick={() => {
                        copy(generatedKey);
                        setCopied(true);
                    }}
                >
                    <small style={{ fontSize: 10 }}>
                        {copied ? <em>copied</em> : "copy"}
                    </small>
                </span>
                <input
                    type="text"
                    name="newKey"
                    disabled={true}
                    className="mt-5"
                    style={{
                        boxSizing: "border-box",

                        paddingLeft: 8,

                        height: 30,
                        width: "100%",

                        background: "#FFFFFF",
                        border: "1px solid #E6E6E6",

                        borderRadius: 3,
                    }}
                    value={generatedKey}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    height: 47,
                }}
            >
                {generatedKey === "" ? (
                    <>
                        <Button
                            variant="secondary"
                            text="Close"
                            onClick={closeRegenerateKey}
                        />
                        <Button
                            variant="primary"
                            text="Regenerate Key"
                            onClick={generateKey}
                        />
                    </>
                ) : (
                    <Button
                        variant="secondary"
                        text="Copy &amp; Close"
                        onClick={closeRegenerateKey}
                    />
                )}
            </div>
        </Modal>
    ) : (
        <></>
    );
}

export default RegenerateApiKeyModal;
