import { Link } from "react-router-dom";
import { useVersion } from "../contexts/VersionContext";
import { routes } from "../utils/routes";
import { useUser } from "../contexts/UserContext";
import bounce from "../utils/bounce";
import { useFeatureFlag } from "configcat-react";

const Footer = () => {
    const version = useVersion();
    const user = useUser();
    const { value: showDocumentation, loading: showDocumentationLoading } =
        useFeatureFlag("showDocumentation", false);

    return (
        <footer>
            <div className="homepage-footer-content">
                <div className="m-35 footer-hero">
                    <h2>Solovis API</h2>
                    <p>
                        Solovis is an institutional investment management
                        technology platform that transforms multi-asset class
                        portfolio management.
                    </p>
                </div>
                <div className="homepage-footer-content-links">
                    <div className="m-35">
                        <h3>
                            <Link
                                to={routes.getApiReferenceRoute(version.active)}
                            >
                                Our APIs
                            </Link>
                        </h3>
                        <ul>
                            <li>
                                <Link
                                    to={routes.getApiReferenceRoute(
                                        version.active
                                    )}
                                >
                                    Portfolio Analytics
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {!showDocumentationLoading && showDocumentation && (
                        <div className="m-35">
                            <h3>
                                <Link
                                    to={routes.getDocumentationRoute(
                                        version.active
                                    )}
                                >
                                    Documentation
                                </Link>
                            </h3>
                            <ul>
                                <li>
                                    <Link
                                        to={routes.getArticlesRoute(
                                            version.active,
                                            "featured"
                                        )}
                                    >
                                        Featured Articles
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={routes.getArticlesRoute(
                                            version.active,
                                            "use-case"
                                        )}
                                    >
                                        Use Cases
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={routes.getArticlesRoute(
                                            version.active,
                                            "example"
                                        )}
                                    >
                                        Examples
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={routes.getArticlesRoute(
                                            version.active,
                                            "case-study"
                                        )}
                                    >
                                        Case Studies
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="m-35">
                        <h3>
                            <Link to={routes.getKeyMgmtRoute()}>
                                Key Management
                            </Link>
                        </h3>
                        <ul>
                            <li>
                                {user.email ? (
                                    <div
                                        onClick={() => bounce(false)}
                                        className="homepage-footer-content-links-auth"
                                    >
                                        Sign Out
                                    </div>
                                ) : (
                                    <a
                                        href={process.env.REACT_APP_GATEWAY_URL}
                                        className="homepage-footer-content-links-auth"
                                    >
                                        Sign In
                                    </a>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-hr">
                <hr />
            </div>
            <div className="copyright-footer">
                &copy;
                <script type="text/javascript">
                    document.write(new Date().getFullYear());
                </script>{" "}
                Solovis, Inc. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
